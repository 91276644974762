html {

  --imp-text-color: rgba(197, 115, 230, 0.883);
}

.purple {
  color: var(--imp-text-color) !important;
  z-index: -1 !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */


.nav-linkprops{
  font-size: 1.8vh !important;
}
.nav-linkprops2{
  padding-left: 0% !important;
margin-left: 0% !important;
}

.sticky {
  background-color: #1b1a2ea9 !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}

.navbar {
  
  margin-left:0px !important;
  
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.5rem 1rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #be50f4 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}
.justify-between{
  justify-content: space-between;
}
.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 1.4em !important;
  width: 2.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  text-align: left;
  padding-right: 0% !important;
  padding-left: 0rem !important;
  font-size: 1.1rem;
}

.nav-link {
  padding: 0rem 0rem !important;
  padding-top: 1%;
}

@media (max-width: 10) {
  .nav-link {
    padding: 0 0 !important;
  }
}
.monkeyquiet{
  background-color: transparent !important;
}
.navbar-nav .nav-item {
  position: relative;

  margin-left: 3vw;
  height: fit-content !important;
  padding: 0% ;
  padding-top: 1.5vh;
}
.nopadding{
  padding-bottom: 1.6% !important;
  padding-top: 1.2% !important;
  height: 40px;
  padding-left: 1%;
}
.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 2.717171717px;
  width: 0;

  border-radius: 116px;
  background: transparent;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.1s ease-out 0s;
  opacity: 0.5;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
  opacity: 1;
  background-color: rgba(197, 115, 230, 0.883) !important;

  
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 0px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  height: fit-content !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  padding-left: 0!important;
  width: fit-content ;
}

.home-content {
  height: fit-content !important;
  padding-top: 5vh !important;
  color: whitesmoke;
  text-align: center !important;
  width: fit-content !important;

}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
  padding-top: 0% !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 5px !important;
}

.main-name {
  color: #cd5ff8;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
}

@media (max-width: 767) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}


.EDD-problem-body {
  padding-top: 5px !important;
  padding-bottom: 0px;
  padding-left: 7px;
  color: white !important;
  text-align: left !important;
  font-size: large;
}



.home-about-section {
  height: fit-content !important;
  position: relative;
  margin: 0 !important;
  padding-bottom: 20px !important;
  padding-top: 0px !important;
}

.mokey {
  display: flex;
  justify-content: space-around;
}
.divider {
  border-left: 1px solid black;
}
.home-about-description {
  height: fit-content !important;
  color: white !important;
  padding-top: 1% !important;
  padding-bottom: 10px !important;
  z-index: -1 !important;

  padding-left: 2% !important;
  text-align: center;

  padding-right: 1% !important;
}
.home-about-description2 {
  display: grid !important;
  grid-auto-flow: row !important;
  gap: 4px !important;
  align-items: center;
  justify-items: center;
  color: white !important;
  padding-top: 0px !important;
width: 100% ;
  padding-bottom: 10px !important;
  text-align: center !important;
  justify-content: center !important;
  

}
.home-about-description3 {
  grid-auto-flow: row !important;
  gap: 4px !important;
  align-items: left !important;
  justify-items: left !important;
  color: white !important;
  padding-top: 0px !important;
width: 600px !important;
  padding-bottom: 10px !important;
  text-align: left !important;
  justify-content: left !important;
  padding-left: 0 !important;
  display: inline-block !important;
  padding-right: 0  !important;

}
.home-about-body {
  padding-top: 0px !important;
  font-size: 90% !important;
  text-align: left !important;
  z-index: -1 !important;

  margin-right: 0px !important;
  padding-right: 4%;
  padding-left: 4% !important;
  width: fit-content !important;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 0px !important;
  display:flex !important;
  position: relative !important;
  padding-inline-start: 0 !important;
  margin: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display:inline-table !important;
  width: 24px !important;
  height: 24px !important;
  text-align: center !important;
  font-size: 1.15em !important;
  line-height: 0.95em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.2s !important;
  margin-top: 0px !important;
  margin-left: 7px !important;
  margin-bottom: 0% !important;

}
.home-social-icons2 {
  position:absolute !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  margin-left: 00px !important;
  margin-bottom: 0% !important;
}
.home-social-icons2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons2:hover::before {
  transform: scale(1.0);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons2:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}


.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.0);
  box-shadow: 0 0 25px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 35px #87209e;
  text-shadow: 0 0 32px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 0px;
  padding-left: 0px;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 120px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}
.corner-wrapper {
  display: block;
  overflow: hidden;
  width: auto;

  border-radius: 50px !important;
  justify-content: center;
  transform: translateZ(0px);
}
.project-card {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}
.home-card {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0 !important;
  display: inline-grid !important;
  justify-content: center !important;
  justify-self: center !important;
  text-align: center !important;
  align-content: center !important;
  width: 50 !important;
  height: fit-content !important;
}
.EDD-card {
  padding-top: 12.5px !important;
  padding-bottom: 12.5px !important;
  padding-left: 25px !important;
  padding-right: 0px !important;
  border-radius: 15%;
  height: width !important;
}
.music-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height:100% !important;
}
.music-card-view {
  box-shadow: 0 2px 2px 2px rgba(119, 53, 136, 0.459) !important;
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.music-card-view:hover {
  transform: scale(1.0) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}


.project-card-view {
  color: white !important;
  background-color: #5b1f77 !important;
  opacity: 1 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  border-radius: 2.7128572% !important;

}
.home-card-view {
  color:  white !important;
  background-color: transparent!important;
  opacity: 1 !important;
  transition: all 0.5s ease 0s !important;
  height: fit-content !important;
  border-radius: 2.7128572% !important;
  border-color: transparent !important;

}

.EDD-card-view{
  color: white !important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  border-radius: 40% !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.project-card-view:hover {
  transform: scale(1.0) !important;
  overflow: hidden !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
  width: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
      border-radius: 50%;

}

.EDD-img-main {
  justify-self: left !important;
  text-align: left !important;
  align-self: left !important;
  padding-left: 0px !important;
  padding-top: 15px;

}


.blog-img {
  margin-left: 2%;
  padding-left: 1% !important;
  padding-bottom: 0% !important;
  padding-right: 1% !important;
  padding-top: 0 !important;
  width: auto;
  justify-content: right !important;
  opacity: 0.8 !important;
  float: right !important;

}

.btn-primary {
  color: #fff !important;

  opacity: .5;
  background-color: #62368600 !important;
  border-color: transparent !important;

  align-items: top !important;
  display: flex ;

  width: 25px;
  height: 30px;
  margin: 0px;
  padding: 0px !important;

}

.center{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height:3 !important;  
  width: 625px !important;
}
.btn-primaree {
  color: #fff !important;
  justify-content: center;
  opacity: 0.6;
  background-color: #62368600 !important;
  border-color: rgba(197, 115, 230, 0.883) !important;

  text-align: bottom !important;
  display: flex ;

  width: 150px;
  height: 35px;
  margin: 0px;
  

}
.btn-primare {
  color: #fff !important;

  opacity: 0.6;
  background-color: #62368600 !important;
  border-color: rgba(197, 115, 230, 0.883) !important;

  display: flex ;
  width: 150px;
  height: 35px;
  margin-left: 2.5%;
  

}
.btn-primare:hover {
  opacity: 1;
  color: #fff !important;
  
  background-color: #62368600 !important;
}
.btn-primaree:hover {
  opacity: 1;
  color: #fff !important;
  
  background-color: #62368600 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn-primary:hover {
  opacity: 1;
  color: #fff !important;
  background-color: #62368600 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  text-align: left;
  height: auto !important;
  padding-left: 20px;
}
.project-heading3 {
  color: white !important;
  font-size: 1.5em !important;
  font-weight: 500 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: auto !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
  margin-right: auto!important;
  padding-left: 0 !important;


}
.project-heading6 {
  color: white !important;
  font-size: vmax !important;
  font-weight: 500 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: auto !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
  margin-right: auto!important;
  padding-left: 0 !important;


}

.spacecringe{
  font-size: small !important;
}
.project-heading4 {
  color: white !important;
  font-size: 1.5em !important;
  font-weight: 500 !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  justify-content: left !important;
  justify-self: left !important;
  justify-items: left !important;
  align-items: left !important;
  height: auto !important;
  margin-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
  height: 25px !important;


}
.project-heading {
  color: white !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 0px !important;
  padding-bottom: 10px !important;
  text-align: left;
  height: auto !important;
  padding-left: 0px;
}
.project-heading-SCORT {
  color: white !important;
  text-align: left;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 0px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}
.react-player {
  position: relative;
  left: 0;
  top: 0;
  width: auto !important;

}
.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.0) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767) {
  .about-img {
    padding-top: 0 !important;
    border-radius: 50%;

  }
}
/* --------- */
/* Resume */
/* --------- */

.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 25px;
  padding-bottom: 50px;
  justify-content: center;
  border-radius: 10% !important;
}

.resume-left {
  padding-right: 80px !important;
  
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
  border-radius: 10% !important;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
  border-radius: 10% !important;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 10% !important;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
  
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
  display: none !important;
}
